// Sign In
$userSignIn-background: url('../../resources/assets/images/login-background.png');
$userSignIn-mobile: url('../../resources/assets/images/login-mobile.png');
.border-login {
  border-right: none;
}
.btn-login {
  width: 80%;
}
.userSignIn {
  background-image: $userSignIn-background;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  object-fit: fit-content;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Medium (md) and larger screens
@include media-breakpoint-up(md) {
  // Login
  .userSignIn {
    background-image: $userSignIn-background;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-login {
    position: absolute;
    top: 48%;
    left: 81%;
    transform: translate(-50%, -50%);
    width: 450px;
    // background: white;
    border-radius: 1rem;
    // box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    z-index: 10;
  }
  .border-login {
    border-right: 1px solid gray;
  }
  .btn-login {
    width: 40%;
  }
  .link-register {
    font-size: 12px;
    margin-left: 2px;
    color: #246aab;
  }
  .link-forgotPass {
    font-size: 12px;
    margin-right: 40px;
    color: #246aab;
  }
  .footer-text {
    position: fixed;
    bottom: 0.5rem;
    right: 10rem;
    color: #d8eef0;
    font-size: 12px;
    text-align: center;
  }
  .text-login {
    width: 80%;
    position: relative;
    left: 10%;
  }
  .title-login {
    text-align: center;
    padding-top: 8%;
    padding-bottom: 5%;
  }
  .text-android-login {
    text-align: center;
    padding-top: 5%;
  }
  .android-image {
    width: 180px;
    height: auto;
  }
  .btnSignIn {
    width: 80%;
    position: relative;
    left: 10%;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
  }
  .btnSignUp {
    width: 80%;
    position: relative;
    left: 10%;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
    margin-top: 3%;
    margin-bottom: 1%;
  }
}
// Small screens
@include media-breakpoint-down(md) {
  //login
  .userSignIn {
    background-image: $userSignIn-mobile;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }
  .card-login {
    border-radius: 1rem;
    width: 80%;
    margin-top: 250px;
  }
  .link-register {
    font-size: 12px;
    margin-left: 2px;
    color: #246aab;
  }
  .link-forgotPass {
    font-size: 12px;
    margin-right: 2px;
    color: #246aab;
  }
  .footer-text {
    text-align: center;
    color: #d8eef0;
    font-size: 9px;
    margin-top: auto;
  }
  .text-login {
    width: 100%;
    position: relative;
  }
  .title-login {
    text-align: center;
    padding-top: 8%;
    padding-bottom: 2%;
  }
  .text-android-login {
    text-align: center;
    padding-top: 3%;
  }
  .android-image {
    width: 110px;
    height: auto;
  }
  .btnSignIn {
    width: 100%;
    position: relative;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
  }
  .btnSignUp {
    width: 100%;
    position: relative;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
    margin-top: 3%;
    margin-bottom: 1%;
  }
}
