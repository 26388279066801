//Medium (md) and larger screens
@include media-breakpoint-up(md) {
  .cardContentBP {
    padding-left: 50px;
    padding-top: 10px;
    padding-right: 27px;
  }
  .checkboxVP {
    display: flex;
    flex-direction: row;
    gap: 150px;
  }
  .buttonBP {
    width: 10%;
  }
  .cardSafety {
    margin-left: 5;
  }
  .summaryBP {
    padding-left: 50px;
    padding-top: 40px;
  }
  .formLayoutVP {
    padding-left: 50px;
    padding-top: 10px;
    padding-right: 50px;
  }
  .formLayoutSummaryFinalBP {
    padding-top: 10px;
    padding-right: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .formLayoutPaymentSummary {
    padding-top: 40px;
    padding-right: 50px;
  }
}
// Small screens
@include media-breakpoint-down(md) {
  .cardContentBP {
    padding-top: 10px;
  }
  .checkboxVP {
    margin-top: 10;
  }
  .paddingRowBP {
    padding-top: ($spacer) !important;
  }
  .buttonBP {
    width: 30%;
  }
  .summaryBP {
    padding-top: 40px;
  }
}
