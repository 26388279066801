.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.rowNum1 {
  background: white;
  padding-bottom: 1%;
  height: 100%;
  box-shadow: 1px 2px 9px #a9a9a9;
  border-radius: 2.5%;
}

.contentText {
  // border-bottom: 2px solid #228B22;
  // border-top: 2px solid #228B22;
  border-left: 3px solid #00aca9;
  // border-right: 2px solid #228B22;
  margin-left: 10%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contentText2 {
  border-left: 2px solid #ff0000;
  margin-left: 10%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.googleCard {
  background: white;
  padding-bottom: auto;
  height: auto;
  box-shadow: '1px 2px 9px #A9A9A9';
  border-radius: '1%';
}

.tableCard {
  background: white;
  padding-top: auto;
  height: 400px;
  box-shadow: 1px 2px 9px #a9a9a9;
  border-radius: '1%';
  line-height: '0.7';
}

.rowChart {
  background: white;
  padding-bottom: 1%;
  height: auto;
  box-shadow: 1px 2px 9px #a9a9a9;
  border-radius: 2.5%;
}

//Medium (md) and larger screens
@include media-breakpoint-up(md) {
  .cardDashboard {
    background-color: #03115f;
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative; // Set position to relative for absolute positioning of circles;
  }
  .cardDashboard2 {
    background-color: #1d319b;
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .cardDashboard3 {
    background-color: #041da8;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .cardBodyAnalyticData {
    padding: 15px 30px;
    // height: '230px',
    background-color: #d3d3d3;
    border-radius: 8px;
  }
}
// Small screens
@include media-breakpoint-down(md) {
  //login
  .cardDashboard {
    background-color: #03115f;
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .cardDashboard2 {
    background-color: #1d319b;
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .cardDashboard3 {
    background-color: #041da8;
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
}
.dashCardTitle {
  color: white;
  font-size: 20px;
  font-family: Poppins, sans-serif;
  font-style: italic;
  opacity: 0.7; // Adjust the opacity as needed
  align-self: flex-start;
  margin-top: 8px;
  margin-left: 15px;
}
.dashHr {
  width: 100%;
  margin: 5px 0;
  color: white;
  opacity: 100%;
  height: 0.3px;
}
.dashCircleCol {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.dashCircle {
  width: 85px;
  height: 85px;
  // position: 'relative',
  background-color: #00aca9bd;
  border-radius: 50% !important;
  color: white;
  // display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  // box-sizing: border-box;
  // padding: 0;
  // border: none;
  // outline: none;
  // box-shadow: none !important;
}
.dashCircle2 {
  @extend .dashCircle;

  background-color: #ff0000a3;
}
.dashCircle3 {
  @extend .dashCircle;

  background-color: #e9aa61b5;
}
.dashCircleText {
  color: white;
  margin-top: 10px;
}
.rowStartDash {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  align-items: stretch;
}
.ccolSafety1 {
  display: grid;
  align-content: center;
  color: white;
  font-size: 30px;
}
.ccolSafety2 {
  color: white;
  margin-top: 1px;
  display: grid;
  align-content: center;
}
.ccolSafety3 {
  color: white;
  margin-top: 5px;
  display: grid;
  align-content: center; // Center the text horizontally
  font-size: 20px; // Move the text 10px to the right
}
