// Variable overrides

$primary: #2d3569;
$info: #00aca9;
$dark: #8e4343;
$black: black;
$grey: #c8c8c8;

$pending: #d6b7ff;

$gray-300: #cfcfcf; // lighten($gray-base, 70%);
$gray-400: #acbbbc; // lighten($gray-base, 60%);
$gray-500: #8fa5a6; // lighten($gray-base, 50%);
$gray-600: #757c7c; // lighten($gray-base, 40%);
$gray-700: #5c7373; // lighten($gray-base, 30%);
$gray-800: #2f3a3a;
$gray-900: #3e3935; //DARK components

// Costum Colors

$body-bg: #ffffff;
$gray-body: #e4e5e6;
$table-dark-bg: #444444;

// Custom Style                                                                                                         s

$navbar-height: 70px;
$navbar-brand-width: 210px;
$navbar-brand-minimized-width: 60px;

$sidebar-width: 250px;
$sidebar-minimized-width: 60px;
// $sidebar-bg: rgb(102, 121, 158);
$sidebar-bg: #2d3569;
$sidebar-color: #692d66;
$sidebar-nav-title-padding-y: 1rem;
$sidebar-nav-title-padding-x: 1.5rem;
$sidebar-nav-link-padding-y: 1rem;
$sidebar-nav-link-padding-x: 1.5rem;
$sidebar-nav-link-hover-bg: #00aca9;
$sidebar-nav-link-hover-color: white;
$sidebar-nav-link-hover-icon-color: white;
$sidebar-nav-link-active-bg: #00aca9;
$sidebar-nav-link-active-icon-color: white;
