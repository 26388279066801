// Here you can add other styles
.profileCard {
  background-color: $primary;
  padding: 20px;
}

.profileText {
  ul li {
    height: 10px;
    p {
      color: black;
    }

    svg {
      color: black;
    }
  }
}

a {
  color: #4e28e9;
}

@media print {
  div.page-footer {
    position: fixed;
    z-index: 9;
    bottom: 0;
    // left: 0;
    // width: 100%;
    padding-left: 1.2cm;
    height: 50px;
    font-size: 15px;
    color: #000000;
    background: rgb(255, 255, 255);
    opacity: 0.5;
    page-break-after: always;
    transform: translateX(0); /* Ensure no translation */
  }

  // @page {
  //   // size: landscape;
  //   size: portrait;
  // }

  @page {
    size: auto;
    // margin: 20mm;
  }
}
// .table-print {
//   background-color: #d9d9d9;
//   padding: 0;
// }
// .col-form-label.table-print {
//   padding: '4px';
//   background-color: '#D9D9D9';
// }

// .col-form-label-print {
//   font-weight: 200;
// }

.table-application-submission {
  border: 1px solid #000000;
  --cui-table-bg: #d9d9d9;
}

.table-cell-print {
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-family: Arial;
  font-weight: normal;
}

// Sign Up
.border-right {
  border-right: none;
}
.btn-register {
  width: 80%;
}
@include media-breakpoint-up(md) {
  .border-right {
    border-right: 1px solid gray;
  }
  .btn-register {
    width: 40%;
  }
}

.deleteAlert {
  color: white;
  background-color: #2d3569;
  border-radius: 50%;
  font-size: 35px;
  height: 50px;
  width: 50px;
}

.deleteBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 60px;
  margin-bottom: -10px;
}

.doneBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: -10px;
}

.doneAlert {
  color: white;
  background-color: #2d3569;
  border-radius: 50%;
  font-size: 35px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webkit-fill-available {
  width: -webkit-fill-available;
}

.print-page {
  width: -webkit-fill-available;
  overflow: auto;
}

.date-clear-background-color .form-control {
  background-color: unset;
}
.date-clear-background-color .input-group-text {
  background-color: unset;
}

.form-control-valid .form-control:valid {
  border-color: var(--cui-border-color);
  background-image: unset;
}
.form-control-valid .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid {
  border-color: var(--cui-border-color);
  color: var(--cui-form-check-label-color);
}
.form-control-valid .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--cui-border-color);
  color: var(--cui-form-check-label-color);
}
.form-control-valid .form-check-input:valid:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, #2d3569);
  border-color: var(--cui-form-check-input-checked-border-color, #2d3569);
}
.form-control-valid .form-multi-select:valid + .form-multi-select,
.form-multi-select.is-valid {
  border-color: var(--cui-border-color);
}
.form-control-valid .date-picker.is-valid .input-group-text {
  border-color: var(--cui-border-color);
}
.form-control-valid .form-select:valid,
.form-select.is-valid {
  border-color: var(--cui-border-color);
}
.form-control-valid .form-select:valid:not([multiple]):not([size]) {
  // background-image: unset;
  --cui-form-select-bg-icon: unset;
  // --cui-form-select-bg-img: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%232f3a3a%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
}
