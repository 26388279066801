.dynamic-circle {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  border: 1px #ccc solid;
  margin-left: 2em;
  position: relative;
  top: -0.5em;
}

.events li {
  display: flex;
  color: #00aca9;
}

.events time {
  position: relative;
  padding: 0 1.5em;
  text-align: center;
}

.events time::after {
  content: '';
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  transform: translateX(50%);
  border-radius: 50%;
  border: 1px #ccc solid;
  width: 0.8em;
  height: 0.8em;
  background: #00aca9;
}

.events > li > span {
  padding: 0 1.5em 2em 1.5em;
  position: relative;
}

.events > li > span::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: 2px #00aca9 solid;
}

.events > li:last-child > span::before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  border-left: none;
}

.events strong {
  display: block;
  font-weight: bolder;
}

.events {
  margin: 1em;
  width: 95%;
}
.events,
.events *::before,
.events *::after {
  box-sizing: border-box;
  font-family: arial;
}
