// Sign In
$userSignUp-background: url('../../resources/assets/images/bg2.2.png');
$userSignUp-mobile: url('../../resources/assets/images/login-mobile2.png');

.link-signup {
  font-size: 12px;
  text-align: center;
  color: #246aab;
}
// Login Medium (md) and larger screens
@include media-breakpoint-up(md) {
  .userSignUp {
    background-image: $userSignUp-background;
    overflow: hidden;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .signupCard {
    background-color: #c7e0e1;
    border-radius: 1rem;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    width: 60vw;
    z-index: 10;
  }
  .footer-signup {
    position: fixed;
    bottom: 0.5rem;
    color: #d8eef0;
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
  .signupCardGroup {
    align-content: center;
    // margin-top: 130px;
  }
  .rowDetailsSignup {
    padding-left: ($spacer * 1.5);
    padding-right: ($spacer * 1.5);
  }
  .registeredCard {
    background-color: #c7e0e1;
    border-radius: 1rem;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    width: 40vw;
  }
}
// Login Small screens
@include media-breakpoint-down(md) {
  .userSignUp {
    background-image: $userSignUp-mobile;
    overflow: hidden;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .signupCard {
    background-color: #c7e0e1;
    border-radius: 1rem;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    width: 100%;
    z-index: 10;
  }
  .footer-signup {
    position: fixed;
    bottom: 0.5rem;
    color: #d8eef0;
    font-size: 12px;
    text-align: center;
    width: 100%;
  }
  .signupCardGroup {
    margin-top: 95px;
  }
  .registeredCard {
    background-color: #c7e0e1;
    border-radius: 1rem;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    width: 90%;
    margin: 0 auto;
  }
}
